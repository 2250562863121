@import "tailwind.css";

@font-face {
    font-family: 'icomoon';
    src:  url('/fonts/icomoon.eot?snlcq8');
    src:  url('/fonts/icomoon.eot?snlcq8#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?snlcq8') format('truetype'),
    url('/fonts/icomoon.woff?snlcq8') format('woff'),
    url('/fonts/icomoon.svg?snlcq8#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'SF Pro Light';
    src: url('/fonts/SF-Pro-Display-Light.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'SF Pro Semibold';
    src: url('/fonts/SF-Pro-Display-Semibold.otf');
    font-weight: 600;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-envelope:before {
    content: "\e904";
}
.icon-twitter-square:before {
    content: "\e903";
}
.icon-angle-down:before {
    content: "\e901";
}
.icon-angle-right:before {
    content: "\e902";
}
.icon-twitter-square1:before {
    content: "\e900";
}
.icon-facebook2:before {
    content: "\ea91";
}
.icon-linkedin:before {
    content: "\eac9";
}





body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
}

@responsive {
    .navbar-bg {
        background-color: rgba(10, 0, 0, 0.37);
    }
}

.font-poppins {
    font-family: 'Poppins', sans-serif;
}

.top-100 {
    top: 100%;
}

.container {
    width: 100%;
    padding-left: 36px;
    padding-right: 36px;
}

@media (min-width: 960px) {
    .container {
        max-width: 1200px;
        margin: auto;
    }
}

.navbar {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    transition: background-color 1s ease-out, top 500ms;
    z-index: 10;
}

.navbar__links {
    color: white;
    font-size: 12px;
    letter-spacing: 0.6px;
}

.navbar__sublinks {
    width: 250px;
}

.navbar__link:hover > ul {
    display: block;
}

.stempeers-introduction__container {
    background-color: #101213;
    overflow: hidden;
}

.stempeers-introduction {
    background-image: url("/images/milky-way.jpg");
    background-size: auto;
    background-position: 50% 60%;
    width: 100%;
    height: 650px;
    z-index: 1;
    animation: stempeersIntroductionImage 1s;
}

@keyframes stempeersIntroductionImage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.stempeers-introduction__title {
    font-size: 35px;
    font-family: 'Poppins', sans-serif;
}

.stempeers-introduction__text {
    animation: fadeInWithScale 2s;
}

@keyframes fadeInWithScale {
    0% {
        opacity: 0;
        transform: scale(2);
    }
    50% { opacity: 0; }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.stempeers-introduction__subtitle {
    font-size: 18px;
}

.nanotag-sponsor {
    background-image: url("/images/nanotag.png");
    background-size: cover;
    background-position: 50% 50%;
}

.section-image-header {
    height: 350px;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .section-image-header {
        height: 250px;
    }
}

.section-image-header__image {
    height: 100%;
    background-size: cover;
    background-position: 50%;
    background-origin: content-box;
}

.team-member__bio {

}

.team-member__bio--show {
    animation: slideDown 1s linear;
}

@keyframes slideDown {
    from { height: 0; }
    to { height: 100%; }
}

.news__image-section {
    max-height: 500px;
    overflow: hidden;
}

.career-resources__links a {
    color: #0000EE;
}

.career-resources__links--bold a {
    font-weight: bold;
}

.fellowships__table td {
    border: 1px solid rgba(0, 0, 0, .1);
    padding: .5rem 1rem;
    font-weight: bold;
}

.fellowships__table a {
    color: #0000EE;
}

.fellowships__table--opportunities td {
    width: 33%;
}

form input[type="text"], form input[type="email"] {
    border: 1px solid rgb(234, 234, 234);
    padding: 10px 15px;
    /*box-shadow: inset 0 2px 1px rgba(0, 0, 0, .25);*/
}

.post-content {
    font-family: 'SF Pro Light', sans-serif;
    color: rgb(119, 119, 119);
    letter-spacing: 0.7px;
}

.post-content p {
    font-size: 15px;
    line-height: 1.75;
    margin: 18px 0 0;
    font-weight: normal;
}

.post-content strong {
    font-family: 'SF Pro Semibold', sans-serif;
    color: rgb(48, 49, 51);
}

.post-content a {
    transition: color 300ms;
}

.post-content a:hover {
    color: #006cff;
}

.post-content ol {
    list-style-type: decimal;
}

.post-content img {
    max-width: 400px;
}

.bloglets__main-post {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    min-height: 500px;
}

.bloglets__main-post__gradient {
    height: 50%;
    width: 100%;
    background: linear-gradient(transparent, black);
    z-index: -1;
}

.bloglets__post img {
    transition: all .5s;
}

.bloglets__post:hover img {
    transform: scale(1.1);
}

.contact-button {
    background-color: rgb(0, 108, 255);
    display: block;
    border: 1px solid transparent;
    transition: all .3s;
}

.contact-form input, .contact-form textarea {
    transition: all 0.2s;
}

.contact-form input:focus, .contact-form textarea:focus {
    border: 1px solid rgb(0, 108, 255);
}

.contact-form input.is-invalid {
    border: 1px solid #e04f1a !important;
}

.bg-light {
    background-color: #f7f7f7;
}